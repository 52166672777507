import React from 'react';
import PropTypes, { shape, arrayOf } from 'prop-types';
import { Link } from 'gatsby';
import cx from 'classnames';

import styles from './post-list.module.sass';

const PostList = ({ posts }) => {
  return (
    <section className={styles.section}>
      <div className="container">
        <h2 className={styles.heading}>All Articles</h2>
        <ul className={styles.list}>
          {posts.map(({ frontmatter: { title, urlPath } }) => {
            return (
              <li className={styles.listItem} key={urlPath}>
                <Link
                  className={cx('text-link--black', styles.postTitle)}
                  to={urlPath}
                >
                  {title}
                </Link>
                <Link
                  className={cx('cta-link--green', styles.cta)}
                  to={urlPath}
                >
                  Read article
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

PostList.propTypes = {
  posts: arrayOf(
    shape({
      frontmatter: shape({
        title: PropTypes.string,
        urlPath: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default PostList;

import React from 'react';
import { graphql } from 'gatsby';
import PropTypes, { shape } from 'prop-types';

import styles from './resources.module.sass';
import Layout from '../../components/layout';
import Banner from '../../components/blocks/banner';
import FeaturedHolder from '../../components/blocks/post-card-list';
import PostList from '../../components/blocks/post-list';
import SEO from '../../components/common/seo';

const Resources = ({
  data: {
    allMarkdownRemark: { nodes },
  },
}) => {
  return (
    <Layout>
      <SEO
        title="PPP Loan Forgiveness Resource Center"
        description="The PPP Loan Forgiveness Resource Center offers insights on topics regarding the Small Business Association's (SBA) Paycheck Protection Program (PPP)."
        urlPath="/resources/"
      />
      <Banner>
        <h1 className={styles.heading}>PPP Loan Forgiveness Resource Center</h1>
      </Banner>
      <FeaturedHolder posts={nodes} />
      <PostList posts={nodes} />
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: frontmatter___rank, order: ASC }
      filter: { fileAbsolutePath: { glob: "**/markdown/resources/**" } }
    ) {
      nodes {
        excerpt
        frontmatter {
          urlPath
          title
          featuredImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

Resources.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: shape({ allMarkdownRemark: shape({ nodes: PropTypes.array }) })
    .isRequired,
};

export default Resources;

import React from 'react';
import PropTypes, { shape, arrayOf } from 'prop-types';
import { Link } from 'gatsby';
import cx from 'classnames';

import FluidImage from '../../../../common/image/fluid';
import styles from './featured-list.module.sass';

const FeaturedList = ({ posts }) => {
  return (
    <section className={styles.featuredList}>
      <ul className={styles.list}>
        {posts.map(
          ({
            excerpt,
            frontmatter: { featuredImage, urlPath: postUrl, title: postTitle },
          }) => {
            return (
              <li className={styles.post} key={postUrl}>
                <div>
                  <Link className={styles.imageLink} to={postUrl}>
                    <FluidImage image={featuredImage} role="presentation" />
                  </Link>
                  <Link
                    className={cx('text-link--black', styles.title)}
                    to={postUrl}
                  >
                    {postTitle}
                  </Link>
                  <p className={styles.excerpt}>{excerpt}</p>
                  <Link className="cta-link--green" to={postUrl}>
                    Read article
                  </Link>
                </div>
              </li>
            );
          },
        )}
      </ul>
    </section>
  );
};

FeaturedList.propTypes = {
  posts: arrayOf(
    shape({
      excerpt: PropTypes.string,
      frontmatter: shape({
        title: PropTypes.string,
        urlPath: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        featuredImage: PropTypes.object,
      }),
    }),
  ).isRequired,
};

export default FeaturedList;

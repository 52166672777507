import React from 'react';
import PropTypes, { shape, arrayOf } from 'prop-types';
import { Link } from 'gatsby';
import cx from 'classnames';

import FeaturedList from './common/featured-list';
import FluidImage from '../../common/image/fluid';
import styles from './post-card-list.module.sass';

const FeaturedHolder = ({ posts }) => {
  const {
    excerpt,
    frontmatter: { featuredImage, urlPath: postUrl, title },
  } = posts[0];
  const featuredListPosts = posts.slice(1, 4);

  return (
    <section className={styles.featuredHolder}>
      <div className="container">
        <h2 className={styles.standardSectionHeader}>Featured Posts</h2>
        <section className={styles.featuredSingle}>
          <Link className={cx(styles.imageLink)} to={postUrl}>
            <FluidImage image={featuredImage} role="presentation" />
          </Link>
          <div className={styles.post}>
            <Link
              className={cx('text-link--black', styles.postTitle)}
              to={postUrl}
            >
              {title}
            </Link>
            <p className={styles.postExcerpt}>{excerpt}</p>
            <Link className="cta-link--green" to={postUrl}>
              Read article
            </Link>
          </div>
        </section>
        <FeaturedList posts={featuredListPosts} />
      </div>
    </section>
  );
};

FeaturedHolder.propTypes = {
  posts: arrayOf(
    shape({
      excerpt: PropTypes.string,
      frontmatter: shape({
        title: PropTypes.string,
        urlPath: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        featuredImage: PropTypes.object,
      }),
    }),
  ).isRequired,
};

export default FeaturedHolder;

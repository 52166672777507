import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './banner.module.sass';

const Banner = ({ children, className, containerClassName, background }) => {
  return (
    <section
      className={cx(
        styles.section,
        { [styles.sectionGrey]: background === 'grey' },
        className,
      )}
    >
      <div className={cx('container', styles.container, containerClassName)}>
        {children}
      </div>
    </section>
  );
};

Banner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  background: PropTypes.oneOf(['green', 'grey']),
};

Banner.defaultProps = {
  className: '',
  containerClassName: '',
  background: 'green',
};

export default Banner;
